import React from "react";

const SectionFiveImg = (props) => {
  const { data } = props;
  return (
    <picture className="PgFFC-SectionFive__img">
      <source media="(max-width: 767px)" srcSet={`${data[1]}`} />
      <source media="(min-width: 768px)" srcSet={`${data[0]}`} />
      <img src={`${data[0]}`} alt="" loading="lazy" />
    </picture>
  );
};
export default SectionFiveImg;
