import React from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/pages/fba-fee-calculator/common/seo_fba-fee-calculator.png";
import HomeIntro from "../components/fba-fee-calculator/Intro";
import SectionOne from "../components/fba-fee-calculator/SectionOne";
import SectionTwo from "../components/fba-fee-calculator/SectionTwo";
import SectionThree from "../components/fba-fee-calculator/SectionThree";
import SectionFour from "../components/fba-fee-calculator/SectionFour";
import SectionFive from "../components/fba-fee-calculator/SectionFive";
import "../components/fba-fee-calculator/index.scss";

const PgFFC = () => {
  return (
    <Layout bgHeader="#f4f7f9">
      <Seo
        title="FREE Amazon FBA Calculator - Seller Revenue, Fees & Profit"
        description="Amazon FBA Calculator helps you to estimate seller`s profit & the revenue potential of any Amazon product. Learn everything about Amazon Fees and get our free app to calculate them automatically"
        page="fba-fee-calculator"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <div className="PgFFC">
        <HomeIntro />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
      </div>
    </Layout>
  );
};
export default PgFFC;
