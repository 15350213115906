import React, { useState } from "react";
import IntroEmailCollect from "../../../components/common/Forms/IntroEmailCollect";
import EmailCollectContainer from "./EmailCollectContainer";
import PropTypes from "prop-types";

const IntroEmailCollectContainer = (props) => {
  const {
    page,
    buttonTextSecondState,
    buttonTextFirstState,
    customClass,
    title,
    placeholderInput,
    redirect,
  } = props;
  const [getRedirectShowText, setRedirectShowText] = useState(false);

  return (
    <EmailCollectContainer
      page={page}
      onSuccess={() => setRedirectShowText(true)}
      redirect={redirect}
    >
      <IntroEmailCollect
        isShowText={getRedirectShowText}
        buttonTextSecondState={buttonTextSecondState}
        buttonTextFirstState={buttonTextFirstState}
        page={page}
        customClass={customClass}
        title={title}
        placeholderInput={placeholderInput}
      />
    </EmailCollectContainer>
  );
};

IntroEmailCollectContainer.propTypes = {
  page: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string,
  redirectUrl: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  btnMaxContent: PropTypes.bool,
  customClass: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  placeholderInput: PropTypes.string,
  redirect: PropTypes.bool,
};

export default IntroEmailCollectContainer;
