import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";

const SectionFourList = (props) => {
  const { data } = props;
  const res = [];
  for (let i = 0; i < data.length; i++) {
    res.push(
      <li key={i}>
        {data[i].beforeLink ? data[i].beforeLink : null}
        {data[i].link ? (
          <>
            <TrackedLink
              category="FbaFeeCalculatorLP"
              action={data[i].link.text}
              target
              path={data[i].link.address}
              showMobileProPopup={data[i].link.showMobileProPopup}
            >
              {data[i].link.text}
            </TrackedLink>
            {data[i].afterLink ? data[i].afterLink : null}
          </>
        ) : null}
      </li>
    );
  }
  return <ul>{res}</ul>;
};
export default SectionFourList;
