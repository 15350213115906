import React from "react";

const SectionTwoParagraph = (props) => {
  const { index, data } = props;
  const res = [];
  const str = data.list[index];
  for (let j = 0; j < str.length; j++) {
    res.push(
      <p key={j}>
        {str[j].beforeBoldText}
        <span>{str[j].boldText}</span>
        {str[j].afterBoldText}
      </p>
    );
  }
  return res;
};
export default SectionTwoParagraph;
