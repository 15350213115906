import React from "react";

const SectionOneList = (props) => {
  const { data } = props;
  const res = [];
  for (let index = 0; index < data.list.length; index++) {
    res.push(
      <li key={index}>
        <img src={data.listImg[index]} alt="" />
        <p>
          {data.list[index].beforeBoldtext}
          <span className="PgFFC-SectionOne__boldЕext">
            {data.list[index].boldText}
          </span>
          {data.list[index].afterBoldText}
        </p>
      </li>
    );
  }
  return res;
};
export default SectionOneList;
