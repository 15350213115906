import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import FastImage from "../../../containers/common/FastImage";

const IntroCalculate = () => {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: { regex: "/fbaFeeCalculator-home_01/" }
          ext: { eq: ".png" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  return (
    <div className="PgFFC-Intro__img">
      <FastImage
        isAdaptive
        isWithRatio
        isFullBreaks
        imageData={imageData}
        imageClassName=""
        imageAltName="home image"
        defaultName="fbaFeeCalculator-home_01"
      />
    </div>
  );
};

export default IntroCalculate;
