import React from "react";
import SectionFourAnswer from "./SectionFourAnswer";

const SectionFourQuestion = (index, data, expanded, setExpanded) => {
  if (index === expanded) {
    return (
      <li
        key={index}
        className="PgFFC-SectionFour__answer"
        onClick={() => setExpanded(expanded === index ? -1 : index)}
      >
        <img src={data.listImg[1]} alt="" loading="lazy" />
        <div className="PgFFC-SectionFour__answerWrap">
          <div className="PgFFC__title PgFFC-SectionFour__mainTitle">
            {data.questionsList[index].question}
          </div>
          <div className="PgFFC-SectionFour__answerText">
            <SectionFourAnswer
              answerParagraphs={data.questionsList[index].answerParagraphs}
            />
          </div>
        </div>
      </li>
    );
  }
  return (
    <li
      key={index}
      className="PgFFC-SectionFour__questions"
      onClick={() => setExpanded(expanded === index ? -1 : index)}
    >
      <img src={data.listImg[2]} alt="" loading="lazy" />
      <div>{data.questionsList[index].question}</div>
    </li>
  );
};

export default SectionFourQuestion;
