import { useState } from "react";
import sectionFourQuestion from "./SectionFourQuestion";
import PG_FFC_CONTENT from "../index.content";

const SectionFourQuestionsList = () => {
  const [expanded, setExpanded] = useState(-1);
  const data = PG_FFC_CONTENT.SecFour
  const res = [];
  for (let index = 0; index < data.questionsList.length; index++) {
    res.push(sectionFourQuestion(index, data, expanded, setExpanded));
  }
  return res;
};
export default SectionFourQuestionsList;
