import React from "react";
import SectionTwoParagraph from "./SectionTwoParagraph";

const SectionTwoList = (props) => {
  const { data, start, end } = props;
  const res = [];
  for (let index = start; index < end; index++) {
    res.push(
      <li key={index}>
        <img src={data.listImg[index]} alt="" loading="lazy" />
        <div>
          <SectionTwoParagraph index={index} data={data} />
        </div>
      </li>
    );
  }
  return res;
};
export default SectionTwoList;
