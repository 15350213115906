import React from "react";
import PG_FFC_CONTENT from "../index.content";
import SectionFourQuestionsList from "./SectionFourQuestionsList";
import SectionFourTipsList from "./SectionFourTipsList";
import "./index.scss";

const SectionFour = () => {
  const data = PG_FFC_CONTENT.SecFour;
  return (
    <section className="PgFFC-SectionFour" id="secFour">
      <div className="PgFFC-SectionFour__container">
        <picture>
          <source media="(max-width: 767px)" srcSet={`${data.listImg[4]}`} />
          <source media="(min-width: 768px)" srcSet={`${data.listImg[3]}`} />
          <img src={data.listImg[3]} alt="" loading="lazy" />
        </picture>
        <h2 className="PgFFC__title PgFFC-SectionFour__mainTitle">
          Tips to Lower Your FBA Fees
        </h2>
        <div className="PgFFC-SectionFour__textOne">
          <p>
            If you’re going to use the FBA program you’ll want to keep your fees
            as low as possible. The less you pay in fees the more profit you’ll
            have left over.
          </p>
        </div>
        <div className="PgFFC-SectionFour__tipsList">
          <div className="PgFFC__title PgFFC-SectionFour__mainTitle">
            Here are some tips to reduce your FBA fees:
          </div>
          <ul>
            <SectionFourTipsList />
          </ul>
        </div>
        <div className="PgFFC-SectionFour__textTwo">
          <p>
            If you’re curious about what exactly your FBA fees will be, just
            check the item with the FBA Calculator widget. Products that are
            compact and lightweight will always have the lowest fees.
          </p>
        </div>
        <div className="PgFFC-SectionFour__questionsList">
          <div className="PgFFC__title PgFFC-SectionFour__mainTitle">
            Frequently Asked Questions
          </div>
          <ul>
            <SectionFourQuestionsList />
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
