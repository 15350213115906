import React from "react";
import SectionFourList from "./SectionFourList";
import TrackedLink from "../../Analytics/TrackedLink";

const SectionFourAnswer = (props) => {
  const { answerParagraphs } = props;
  const answer = [];
  for (let i = 0; i < answerParagraphs.length; i++) {
    const paragraphs = answerParagraphs[i];
    answer.push(
      <div key={i}>
        {paragraphs.beforeLink ? paragraphs.beforeLink : null}
        {paragraphs.link ? (
          <>
            <TrackedLink
              category="FbaFeeCalculatorLP"
              action={paragraphs.link.text}
              target
              path={paragraphs.link.address}
              showMobileProPopup={paragraphs.link.showMobileProPopup}
            >
              {paragraphs.link.text}
            </TrackedLink>
            {paragraphs.afterLink ? paragraphs.afterLink : null}
          </>
        ) : null}
        {paragraphs.list ? <SectionFourList data={paragraphs.list} /> : null}
      </div>
    );
  }
  return answer;
};

export default SectionFourAnswer;
