import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const SectionThree = () => {
  return (
    <section className="PgFFC-SectionThree" id="secThree">
      <div className="PgFFC-SectionThree__container">
        <TrackedLink
          category="FbaFeeCalculatorLP"
          action="GetForFree"
          target
          classes="PgFFC__btn"
          path="https://chrome.google.com/webstore/detail/amazon-fba-calculator-fre/dkgjopcolgcafhnicdahjemapkniikeh?utm_source=site&utm_medium=amzscout&utm_campaign=fba_calc_lp"
        >
          Get for FREE
        </TrackedLink>
        <div className="PgFFC__title PgFFC-SectionThree__title">
          Know the FBA fees for any product that you research!
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
