import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import IntroEmailCollectContainer from "../../../containers/common/forms/IntroEmailCollectContainer";
import { PAGES } from "../../../helpers/constants";
import TrackedLink from "../../Analytics/TrackedLink";
import IntroCalculate from "./IntroCalculate";

const IntroBlock = () => {
  const {
    media: { isTabletOrLess },
    auth: { isAuth },
  } = useContext(MediaAuthContext);

  const path =
    "https://chrome.google.com/webstore/detail/amazon-fba-calculator-fre/dkgjopcolgcafhnicdahjemapkniikeh?utm_source=site&utm_medium=amzscout&utm_campaign=fba_calc_lp";
  if (isAuth) {
    if (isTabletOrLess) {
      return (
        <>
          <div className="PgFFC-Intro__blockBtn">
            <p>Please check button below if you are not redirected</p>
            <TrackedLink
              category="FbaFeeCalculatorLP"
              action="GetFbaCalculatorForFree"
              target
              classes="PgFFC__btn"
              path={path}
            >
              Get FBA Calculator for FREE
            </TrackedLink>
          </div>
          <IntroCalculate />
        </>
      );
    } else {
      return (
        <>
          <IntroCalculate />
          <div className="PgFFC-Intro__blockBtn">
            <p>Please check button below if you are not redirected</p>
            <TrackedLink
              category="FbaFeeCalculatorLP"
              action="GetFbaCalculatorForFree"
              target
              classes="PgFFC__btn"
              path={path}
            >
              Get FBA Calculator for FREE
            </TrackedLink>
          </div>
        </>
      );
    }
  } else {
    return (
      <>
        <div className="PgFFC-Intro__blockBtn PgFFC-secondBlockBtn">
          <IntroEmailCollectContainer
            title="Calculate your profit with Amazon Calculator"
            customClass="PgFFC-Intro-emailCollect"
            buttonTextFirstState="Get the full version for Free!"
            buttonTextSecondState="Get the full version for Free!"
            page={PAGES.FBA_FEE_CALCULATOR}
            placeholderInput="Enter your email to get Amazon FBA Calculator"
          />
        </div>
        <IntroCalculate />
      </>
    );
  }
};
export default IntroBlock;
